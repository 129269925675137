














































































































@import '../../../shared/assets/scss/core/breakpoints.scss';

.upload-avatar-form {
  width: 400px;
  margin: 0 30px;

  @include breakpoint-lg-down() {
    width: unset;
    margin: unset;
  }
}
