
























































































































































@import '../../../shared/assets/scss/core/breakpoints.scss';

.related-contact {
  --avatar-size: 140px;
  --avatar-font-size: 1.5rem;
  --action-icon-size: 30px;
  --action-button-gap: 7px;

  .related-contact-form {
    --input-group-width: 300px;
  }
  .contact-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .contact-header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    gap: 35px;
    border-bottom: 1px solid var(--color-grey-dark);
  }
  .contact-avatar {
    --width: var(--avatar-size);
    --height: var(--avatar-size);
    --font-size: var(--avatar-font-size);
  }
  .contact-avatar-container {
    position: relative;
  }
  .profile-picture-actions {
    position: absolute;
    right: -24px;
    top: calc(var(--avatar-size) - var(--action-icon-size));
    width: calc(var(--action-icon-size) * 2 + var(--action-button-gap));
    justify-content: space-between;
    .action-icon {
      --background-color: var(--color-primary-dark);
      --color: var(--color-white);
      --width: var(--action-icon-size);

      outline: 3px solid var(--color-white);
    }
  }
  .contact-details {
    overflow-wrap: anywhere;
  }

  @include breakpoint-lg-down() {
    --avatar-size: 50px;
    --avatar-font-size: 1rem;

    .related-contact-form {
      --input-group-width: 100%;
    }
    .contact-header {
      flex-direction: column;
      gap: 0;

      .contact-avatar-container {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;
      }
      .profile-picture-actions {
        position: static;
        margin-left: auto;
        flex-wrap: nowrap;
        width: unset;
        gap: var(--action-button-gap);
      }
      .header-group {
        align-self: flex-start;
      }
    }
  }
}
