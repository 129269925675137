























































.revision-accordion {
  border-top: 1px solid var(--color-primary-darker);
}

.fold-button {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px 12px;
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: var(--color-white);
  }

  /deep/ &.not-collapsed {
    .fold-icon {
      transform: rotate(180deg);
    }
  }

  .fold-icon {
    --font-size: 10px;

    margin-left: auto;
    transition: transform 200ms ease-in;
  }
}
.revision-table {
  margin-top: 8px;
}

.question-group {
  border-bottom: 1px solid var(--color-primary-darker);
  padding: 8px 0;
}
