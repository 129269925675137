















































/deep/ .tox .tox-edit-area__iframe {
  background-color: var(--color-grey-light);
}
