





























































































.action-container {
  width: 200px;
  text-align: center;
}

.b-table {
  --table-btn-link-decoration: none;
  --questionmark-icon-background-color: var(--color-grey-base);
  --questionmark-icon-color: var(--color-primary-darker);
  --table-icon-font-size: 1rem;
}
