





















































































































































































































































































































































































































































































































































































































































































































































































































































.consent-content {
  .question {
    position: relative;
    .help-tooltip {
      position: relative;
    }
  }
  .question-yesno {
    position: relative;
  }
  /deep/ .help-tooltip {
    position: unset;
    .tooltip-inner {
      left: unset;
    }
  }
}
