


















































































































.avatar-upload-preview-variables {
  --preview-width: 100%;
  --preview-height: 100%;
}

.preview-container {
  width: var(--preview-width);
  height: var(--preview-height);
  background-color: var(--color-grey-base);
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 4%;
    transition: transform 200ms ease-in;
  }

  .crop-overlay {
    position: absolute;
    inset: 0;
    border-radius: 50%;
    outline: 100vw solid rgba(0, 0, 0, 0.6);
  }

  .avatar-action {
    position: absolute;
    z-index: 1;

    &.rotate-counter-clockwise {
      top: 8px;
      left: 8px;
    }

    &.rotate-clockwise {
      top: 8px;
      right: 8px;
    }

    &.delete {
      bottom: 8px;
      right: 8px;
    }
  }

  .action-icon-container {
    --width: 28px;
    box-shadow: var(--box-shadow-base);
  }

  .action-icon {
    --font-size: 1.1rem;
  }
}
