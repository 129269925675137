







































































































.cause-content {
  max-width: 250px;
  overflow-wrap: break-word;
}
