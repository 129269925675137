




















.list-item {
  display: flex;
  align-items: stretch;
}

.primary-content {
  margin-right: auto;
}
