




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
@import '../../assets/scss/elements/consentAdditional';

.consent-table .consent-actions {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  margin-bottom: 20px;
}
.functions {
  margin-top: 25px;

  /deep/ .custom-radio {
    label span {
      position: absolute;
      top: -24px;
      left: -48px;
      white-space: nowrap;
      display: inline-block;
    }

    &:first-child {
      margin-right: 42px;

      @include breakpoint-lg-down {
        margin-left: 10px;
      }
    }
  }
}

.function.mobile {
  margin-right: 0px;
}
