
































































































































































































































@import '../../../shared/assets/scss/core/breakpoints.scss';

.primary-institution {
  max-width: 400px;
  padding-bottom: 50px;
}
.contact-information-item {
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--color-grey-dark);
}
.contact-information-actions {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
}

@include breakpoint-lg-down() {
  .primary-institution {
    max-width: unset;
  }
  .contact-information-actions {
    position: sticky;
    top: 60px;
    padding: 10px 0;
    background-color: var(--color-grey-light);
    z-index: 1;
  }
}
