



















































.table-row {
  &.not-hoverable:hover {
    background: var(--color-base, var(--color-white));
  }
}
