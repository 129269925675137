.consent-table {
  display: block;

  .header {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    border-bottom: 1px $color-darkblue solid;
    padding-bottom: 5px;

    .title {
      font-size: 24px;
      font-weight: 900;
      margin-top: 20px;
      flex: 1;
    }

    .profile {
      display: inline-flex;
      width: 170px;
      padding: 2px;

      .profile-name {
        font-size: 16px;
        font-weight: 900;
        margin-top: 10px;
        margin-left: 7px;

        .instition-name {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }

  .functions {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;

    .title {
      flex: 1;
      min-width: 120px;
    }

    .profile {
      width: 170px;
      padding: 10px 5px 10px 20px;
      margin-left: 2px;
    }
  }

  .consent-content {
    background-color: $color-white;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;

    .additional-questions{
      &:first-child {
        padding-top: 2px;
      }
      &:last-child {
        padding-bottom: 2px;
      }
    }
    .questions {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      width: 100%;
      margin-bottom: 2px;

      &.complex-question {
        margin-bottom: 0;
        padding-bottom: 0;

        .question {
          padding: 15px 5px 5px 15px;
        }
      }

      &.optional-question {
        height: 100px;
      }

      .question {
        flex: 1;
        padding: 15px 5px 15px 15px;
        font-weight: bold;
        background-color: $color-grey-light;
        min-width: 120px;
        word-break: break-word;

        .consent-complex-question {
          font-weight: normal;
          margin-top: 15px;
          padding-left: 20px;
        }

        &.sub {
          font-weight: normal;
          padding-left: 20px;
        }
      }

      .answer {
        background-color: $color-grey-light;
        margin-left: 2px;
        width: 170px;
        padding: 10px 5px 10px 20px;
        .complex-answer {
          display: flex;
          align-items: center;
        }
        .custom-radio {
          margin-right: 24px;
        }
        .answer-input {
          border: none;
          margin-bottom: 0;
        }
        &.disabled {
          background-color: $color-grey-base;
        }

        .answer-textarea-input {
          margin-bottom: 0;
          height: 100%;
        }
        .complex-answers {
          width: 150px;
          position: absolute;
          bottom: 10px;

          .complex-answer {
            display: block;
            height: 40px;
            padding-top: 18px;
            width: 100%;
          }
        }
        &.disabled {
          background-color: $color-grey-base;
        }

        &.anwser-consent-complex {
          position: relative;
        }

        .answer-textarea-input {
          margin-bottom: 0;
          height: 100%;
        }
      }
    }

    .questions-mobile {
      background-color: $color-grey-light;
      min-width: 70px;
      padding: 16px 0;
      margin: 10px 0;

      .question {
        padding-bottom: 8px;
        padding-top: 5px;

        .complex-consent {
          border-top: 1px solid $color-grey-dunkel;
          margin-top: 20px;
          padding-top: 18px;

          .complex-question {
            padding-bottom: 8px;
          }
        }

        .complex-question {
          padding-top: 17px;
        }
      }

      .optional-question {
        margin-top: 20px;

        .answer-textarea-input {
          margin-top: 10px;
          height: 100px;
        }
      }

      .answer {
        .complex-answers {
          position: absolute;
          width: 100%;
          padding-right: 35px;
          bottom: 10px;

          .complex-answer {
            display: block;
            height: 40px;
            padding-top: 18px;
            width: 100%;
          }
        }
      }

      .answer-textarea-input {
        margin-bottom: 0;
        max-height: 120px;
      }
    }

    .questions-single {
      margin-left: 0;
      margin-bottom: 2px;

      .question {
        margin-left: -2px;
        padding-left: 20px;
        padding-bottom: 20px;
        padding-top: 20px;
        background-color: $color-grey-light;
        margin-right: 2px;
        font-weight: bold;

        .complex-question {
          padding-top: 17px;
        }
      }

      .answer {
        background-color: $color-grey-light;
        padding-bottom: 20px;
        padding-top: 20px;

        .complex-answers {
          padding-left: 15px;
          position: absolute;
          bottom: 10px;

          .complex-answer {
            display: block;
            text-align: center;
            width: 100%;
          }
        }
      }

      .optional-question {
        background-color: $color-grey-light;
        margin-top: 2px;
        padding-top: 20px;
        padding-bottom: 10px;

        .answer-textarea-input {
          margin-top: 10px;
          height: 100px;
        }
      }
    }

    .consent-item {
      padding-bottom: 0;
    }

    .answer-text {
      width: 100%;
      font-weight: bold;
      text-align: center;
    }

    textarea::-webkit-scrollbar {
      width: 7px;
    }

    textarea::-webkit-scrollbar-thumb {
      background-color: $color-grey-base;
    }
  }
}

.consent-mobile {
  width: 100%;
  margin-bottom: 30px;

  .single-col {
    border-bottom: 1px solid $color-grey-darker;
    margin-bottom: 7px;

    .header .profile-name {
      bottom: 20px;
    }

    .function {
      margin-top: 10px;
    }
  }

  .consent-content {
    @include breakpoint-lg() {
      background-color: $color-white;
    }
  }

  .header {
    @include breakpoint-lg() {
      border-bottom: 1px solid $color-grey;
      margin-bottom: 7px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }

    @include breakpoint-lg-down() {
      .instition-name {
        font-size: 16px;
        font-weight: bold;
        padding: 20px;
        width: 100%;
        text-transform: uppercase;

        .theme-guardian &,
        .theme-child & {
          color: $color-white;
          background-color: $color-primary-dark-guardian;
        }

        .theme-employee & {
          color: $color-white;
          background-color: $color-primary-dark-employee;
        }

        .theme-guardian .view-mode &,
        .theme-child .view-mode & {
          color: $color-primary-darker-guardian;
          background-color: $color-grey-base;
        }

        .theme-employee .view-mode & {
          color: $color-primary-darker-employee;
          background-color: $color-grey-base;
        }
      }
    }

    .profile {
      margin-top: 20px;
      flex: 1;

      @include breakpoint-lg-down() {
        padding-left: 10px;
      }

      .user.inline {
        vertical-align: middle;
      }

      .profile-name {
        display: inline-block;
        position: relative;
        font-size: 16px;
        margin-left: 7px;
        text-transform: uppercase;
        font-weight: bold;
        vertical-align: middle;

        .instition-name {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }

    .function {
      width: 170px;
      padding-top: 40px;
      text-align: center;

      &.mobile {
        width: 90px;
        margin-top: -100px;
        float: right;
      }

      .filter-all {
        position: absolute;
        margin-left: -40px;
        margin-top: 3px;
      }
    }
  }

  .question-yesno {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 60px;

    @include breakpoint-lg-down {
      margin-bottom: 10px;
    }

    &.complex-question {
      margin-bottom: -2px;
      min-height: 50px !important;

      .question {
        padding: 15px 5px 5px 15px;
      }
    }

    .question {
      flex: 1;
      overflow-x: visible;
      word-wrap: break-word;

      @include breakpoint-lg {
        margin-right: 2px;
        background-color: $color-grey-light;
      }

      .consent-complex-question {
        font-weight: normal;
        margin-top: 20px;
      }

      &.sub {
        font-weight: normal;
        padding-left: 20px;
      }
    }

    .answer {
      padding: 15px;
      text-align: center;
      width: 170px;
      margin-bottom: 2px;

      &.revision-history {
        width: 160px !important;
        padding-right: 10px;
      }

      @include breakpoint-lg {
        background-color: $color-grey-light;
      }

      @include breakpoint-lg-down {
        margin-bottom: 10px;
      }

      .complex-answers {
        position: absolute;
        bottom: 10px;
        text-align: center !important;
        width: 100%;

        &.mobile {
          bottom: 30px;
        }

        .complex-answer {
          padding-top: 5px;

          &.mobile {
            padding-top: 20px !important;
          }
        }
      }

      &.mobile {
        width: 82px;
      }

      &.anwser-consent-complex {
        position: relative;
      }
    }
  }

  .question {
    padding: 15px;
    margin-bottom: 2px;

    @include breakpoint-lg {
      background-color: $color-grey-light;
      font-weight: bold;
    }

    @include breakpoint-lg-down {
      margin-bottom: 10px;
    }

    &.question-textarea {
      margin-top: -2px;

      @include breakpoint-lg-down {
        margin-top: -12px;
      }
    }
  }

  .answer {
    @include breakpoint-lg {
      background-color: $color-grey-light;
    }
  }

  &:last-child {
    margin-bottom: 60px;
  }
}

.consent-mobile {
  .mobile {
    bottom: 0 !important;
    width: 110px !important;

    &.textarea {
      width: 200px !important;
    }

    .custom-radio {
      display: inline-block;

      label {
        position: relative;
        display: inline-block;

        span {
          position: absolute;
          top: -20px;
          left: -28px;
        }
      }
    }
  }
}

.theme-employee {
  .consent-table-mobile {
    .consent-mobile .header .institution-name {
      background-color: $color-primary-dark-employee;
    }

    &.view-mode {
      .consent-mobile .header .institution-name {
        background-color: $color-grey-base;
      }
    }
  }
}

.theme-guardian,
.theme-child {
  .consent-table-mobile {
    .consent-mobile .header .institution-name {
      background-color: $color-primary-dark-guardian;
    }

    &.view-mode {
      .consent-mobile .header .institution-name {
        background-color: $color-grey-base;
      }
    }
  }
}
