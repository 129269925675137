@import '../../core/variables.scss';
.el-select-dropdown {
  .el-select-group-select-all .custom-control-label {
    line-height: 40px;
  }
}
.aula-search {
  min-height: 50px;
  position: relative;
  /deep/ .el-tag {
    position: relative;
  }
  /deep/ .el-select__tags-text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-right: 0.5rem;
  }
  /deep/ .el-tag__close {
    position: absolute;
    top: 10px;
    right: 2px;
    color: white;
    font-size: 14px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-button:start,
  &::-webkit-scrollbar-button:end {
    border-left: 0;
    background-image: none;
  }

  &::-webkit-scrollbar-button:start,
  &::-webkit-scrollbar-button:end {
    display: none;
  }

  /deep/ .el-input__suffix,
  /deep/ .el-select__caret {
    height: 97% !important;
  }
}

.aula-search-outer {
  position: relative;
  border-radius: 5px;
  .is-focus {
    border-radius: 5px;
    border: 0;
    outline: 0;
    .theme-employee & {
      box-shadow: 0px 0px 2px 0.01rem $color-primary-darker-employee;
    }
    .theme-guardian & {
      box-shadow: 0px 0px 2px 0.01rem $color-primary-darker-guardian;
    }
    .theme-child & {
      box-shadow: 0px 0px 2px 0.01rem $color-primary-darker-child;
    }
    .theme-admin & {
      box-shadow: 0px 0px 2px 0.01rem $color-primary-darker-admin;
    }
  }
  .cover {
    position: fixed;
    height: 100%;
    width: 100%;
    right: 3px;
    top: 0;
    bottom: 0;
    opacity: 1;
    z-index: 2000;
    transition-duration: 0.5s;
    transition-property: opacity;
  }
  .button-close {
    position: absolute;
    right: var(--padding-right, 32px);
    top: 12px;
    z-index: 1;
    height: 28px;
    width: 28px;
    opacity: 0.8;
    background-color: transparent;

    &:hover {
      opacity: 1;
    }

    .icon-container {
      width: 100%;
      height: 100%;

      .aula-icon {
        position: static;
        --font-size: 18px;
      }
    }
  }
}

.is-updating-selection {
  cursor: progress;
}
