







































































































@import '../../../shared/assets/scss/core/breakpoints.scss';

.related-contacts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 50px;
  gap: 100px;

  @include breakpoint-lg-down() {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
