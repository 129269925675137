



































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.cell-badge {
  --position: relative;
  display: inline-block;
  padding-right: 4px;
}
.custom-icon-variables {
  --table-icon-font-size: 0.5em;
}
.custom-icon {
  margin-left: 4px;
}

.custom-table-icon {
  font-size: 8px;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 1000;

  .create-additional-data-container & {
    right: -5px;
  }
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.filter-spacing {
  padding-right: 30px;
}

.aula-table /deep/ .table-responsive {
  min-height: 350px;
  position: sticky;
  margin-bottom: 10px;
  & > table {
    width: calc(100% - 1px);
  }
}
.table-responsive {
  /deep/.sorting {
    padding-left: 3em;
    padding-right: 17px;
    &:before {
      left: 2em !important;
    }
    &:after {
      left: 1.5em !important;
    }
  }
}
.page-option-select {
  width: 90px;
}

.sticky-header {
  /deep/ .table-responsive {
    overflow-y: visible;
    overflow-x: visible;
  }
  /deep/ .b-table.table > thead > tr > th {
    position: sticky;
    z-index: 2;

    /*
      The distance is 1px less than the topbar because the th is pushed down 1px due to a border of a parent element.
    */
    top: 119px;
    @include breakpoint-xl-down() {
      top: 79px;
    }
    @include breakpoint-lg-down() {
      top: 57px;
    }
  }
}
