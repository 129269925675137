






























































































































































































@import '../../../shared/assets/scss/core/variables.scss';
/deep/ .aula-table {
  thead th:last-child {
    padding-right: 50px;
  }
}

.select-all-checkbox {
  position: absolute;
  right: 25px;
  top: 90px;
  z-index: 1050;
}

.icon-Aula_down-arrow,
.icon-Aula_up-arrow {
  font-size: 10px;
}
/deep/ .b-table tbody tr td {
  vertical-align: top;
}
