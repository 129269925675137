









































































































































.contact-form-variables {
  --input-group-width: auto;
}
.contact-form {
  width: 100%;
  .contact-form-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;

    .contact-icon {
      --width: 30px;
      --background-color: var(--color-grey-base);
      .email-icon {
        --font-size: 0.7rem;
      }
    }
    .contact-label {
      font-weight: bold;
      width: 70px;
    }
    .email-link {
      color: var(--color-primary-dark);
    }
  }
  .contact-form-input-group {
    width: var(--input-group-width);
  }
}
