

























































































.api-information {
  background-color: var(--color-grey-light);
  padding: 20px;
  border-radius: 15px;
  .api-url code {
    font-size: 1rem;
    font-weight: bold;
  }
}
.error-trace {
  padding: 5px 20px;
}
