



































































































































































input.form-control {
  --input-border-color: var(--color-grey-base);
}

.spinner {
  width: 50px;
  height: 50px;
  position: absolute;
  transform: translate(-25%, -25%);
}
