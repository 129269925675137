

















































































































































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

footer {
  position: relative;
  text-align: right;
  margin-top: 40px;
  padding-bottom: 40px;
  @include breakpoint-lg-down() {
    margin-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 80px;
  }
  button {
    margin-right: 15px;
  }
  .submit-container {
    position: relative;
    display: inline-block;
  }
  .aula-spinner {
    width: 80px;
    height: 80px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    &.mobile {
      left: -7px;
      top: -8px;
    }
  }
  .aula-circle {
    display: inline-block;
    margin: auto;
    padding-top: 18px;
  }
  &.footer-new-message-mobile {
    .aula-circle {
      color: $color-primary-darker;
      padding-top: 18px;
    }
    .aula-circle-large {
      border-radius: 4px;
      height: 50px;
      width: 110px;
      background-color: unset;

      &.send-btn {
        .aula-circle {
          color: $color-white;
        }
      }
    }
    .aula-circle-small {
      border-radius: inherit;
      height: 100%;
      width: 100%;
      left: unset;
      top: unset;
    }
  }
  .aula-circle-large.disabled {
    opacity: 0.65;
  }
  .doubleBtn {
    width: 50%;
    float: left;
    .tool-link {
      margin-left: 0;
      margin-right: calc(100% - 90px);
    }
    &.first {
      .tool-link {
        margin-left: calc(100% - 90px);
        margin-right: 0;
        .aula-circle-large {
          background-color: transparent;
          box-shadow: 0 0 0 transparent;
          color: $color-primary-dark-employee;
        }
        .aula-circle-small {
          background-color: transparent;
          box-shadow: 0 0 0 transparent;
        }
      }
    }
    .aula-spinner {
      &.mobile {
        right: unset;
        margin-left: unset;
        margin-right: unset;
      }
    }
  }
}

.footer-new-message-mobile {
  .doubleBtn {
    float: unset;
    position: absolute;
    right: 15px;
    width: unset;
    &.first {
      right: 130px;
    }
  }
}
