































































































































































































































@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';

.consent-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@include breakpoint-lg-down() {
  .consent-header-container {
    color: var(--color-white);
    position: sticky;
    top: 0;
    z-index: $modal-sticky-header-z-index;
  }

  .consent-page {
    position: fixed;
    top: 0;
    left: 0;
    bottom: var(--menu-item-height);
    z-index: $consent-header-mobile-z-index;
    overflow: auto;
  }
}
