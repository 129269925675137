























































































































































.consent-button {
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.consent-spinner {
  width: 100px;
  height: 100px;
}
.consent-list {
  list-style: none;
}
.consent-actions {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
}
