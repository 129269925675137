































.badge-notification-variables {
  --position: absolute;
}
.badge-notification {
  position: var(--position);
}
