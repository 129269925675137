



















































.contact-primary-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}
.institution-select[aria-disabled='true'] {
  --border-color: var(--color-grey-darker);
}
