











































































































































































@import '../../../shared/assets/scss/core/breakpoints.scss';

.contact-card {
  --avatar-size: 200px;
  --avatar-font-size: 3rem;
  --action-icon-size: 30px;
  --action-button-gap: 7px;

  display: flex;
  gap: 40px;

  .main-contact-form {
    --input-group-width: 400px;
  }
  .contact-avatar {
    --width: var(--avatar-size);
    --height: var(--avatar-size);
    --font-size: var(--avatar-font-size);
  }
  .contact-avatar-container {
    position: relative;
  }
  .profile-picture-actions {
    position: absolute;
    right: 0;
    top: calc(var(--avatar-size) - var(--action-icon-size));
    width: calc(var(--action-icon-size) * 2 + var(--action-button-gap));
    justify-content: space-between;
  }
  .information-container {
    flex-grow: 1;
    .contact-details {
      display: flex;
      padding-top: 20px;
      overflow-wrap: anywhere;

      &[data-main-details] {
        gap: 50px;
      }
    }
    .main-details {
      max-width: 50%;
    }
    .consent-container {
      margin-top: 15px;
      > div:first-child {
        border-top: 1px solid var(--color-grey-dark);
      }
      > div {
        border-bottom: 1px solid var(--color-grey-dark);
      }
    }
  }

  @include breakpoint-lg-down() {
    --avatar-size: 50px;
    --avatar-font-size: 1rem;

    flex-direction: column;
    gap: 0;

    .main-contact-form {
      --input-group-width: 100%;
    }
    .contact-avatar-container {
      display: flex;
      align-items: center;
      gap: 15px;

      .profile-picture-actions {
        position: static;
        margin-left: auto;
        flex-wrap: nowrap;
        gap: var(--action-button-gap);
        width: unset;
      }
    }
    .information-container {
      .contact-details {
        flex-direction: column;
        gap: 0;
      }
      .main-details {
        max-width: unset;
      }
    }
  }
}
