





















































.additional-data-revision-table {
  --color-base: var(--color-grey-base);
  --border-color-top: transparent;
  --border-color-right: transparent;
  --border-color-bottom: transparent;
  --border-color-left: transparent;
  --table-btn-link-decoration: none;

  th {
    text-transform: none;
  }

  .body {
    --color-base: var(--color-white);
  }
}
