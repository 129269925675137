















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';

/deep/.aula-table th {
  .icon-Aula_down-arrow {
    right: 10px;
  }
  &.creation-date {
    min-width: 210px;
  }
}
.show-hide {
  background-color: $color-white;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
}
