












































































































































































































































































































































































































































@import '../assets/scss/core/variables';
@import '../assets/scss/core/breakpoints';

.functions {
  display: flex;
  justify-content: end;
}

.contact-info-title {
  outline: none;
}

hr {
  margin-top: 0;
}

ul {
  list-style: none;
  padding: 0;
}

.aula-profile-container {
  .help-text {
    margin-bottom: 40px;
    @include breakpoint-lg-down() {
      margin-top: 0px;
      margin-bottom: 20px;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.btn-collapse-link {
  background: none;
  padding: 0;
  text-indent: 10px;
}

.terms-policy {
  a {
    color: var(--color-primary-dark);
  }
}

.tabs-nav {
  margin-top: 5px;
  margin-bottom: 20px;
  .tab-item {
    font-size: 15px;
  }
}
