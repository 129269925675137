

































































































.role-table-header {
  width: 150px;
  white-space: unset;
  vertical-align: top;
}
i.icon-Aula_check {
  --table-icon-font-size: 2rem;

  color: var(--color-primary-dark);
}
