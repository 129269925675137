






































































td.table-cell {
  background-color: var(--color-base) !important;
  border-right-color: var(--border-color-right, var(--color-base)) !important;
  border-left-color: var(--border-color-left, var(--color-base)) !important;
  border-top-color: var(--border-color-top, var(--color-base)) !important;
  border-bottom-color: var(--border-color-bottom, var(--color-base)) !important;

  &.not-hoverable:hover {
    cursor: auto;
    background-color: var(--color-base) !important;
  }

  &.not-vertical-aligned {
    vertical-align: top;
  }

  &.collapse-right {
    border-right-style: unset !important;
    border-right-width: 0 !important;
  }

  &.collapse-left {
    border-left-style: unset !important;
    border-left-width: 0 !important;
  }

  &.collapse-bottom {
    border-bottom-style: unset !important;
    border-bottom-width: 0 !important;
  }

  &.collapse-top {
    border-top-style: unset !important;
    border-top-width: 0 !important;
  }
}
